import {
  FIREBASE_POSTS_NODE_NAME,
  FIREBASE_TRANSACTIONS_NODE_NAME,
} from '../constants/firebase.constants';
import { FirebaseNode, FirebaseNodeType } from '../models/firebase.model';

export function buildPostNodeIdPath(firebaseNode: FirebaseNode): string {
  if (firebaseNode.nodeType === FirebaseNodeType.TRANSACTION) {
    return `${FIREBASE_POSTS_NODE_NAME}/${firebaseNode.postType}/${firebaseNode.postId}/${FIREBASE_TRANSACTIONS_NODE_NAME}/${firebaseNode.transactionId}`;
  } else {
    return `${FIREBASE_POSTS_NODE_NAME}/${firebaseNode.postType}/${firebaseNode.postId}`;
  }
}
