import { Observable } from 'rxjs';
import { PostTypeEnum } from '../pages/create-post/create-post.model';

export enum FirebaseNodeType {
  POST = 'POST',
  TRANSACTION = 'TRANSACTION',
}

export interface FirebaseNode {
  nodeType: FirebaseNodeType;
  postType: PostTypeEnum;
  postId: string;
  transactionId?: string | undefined;
  isPostOwner?: boolean | undefined;
}

export interface FirebaseNodeObservable extends FirebaseNode {
  changes: Observable<any>;
}
